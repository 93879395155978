<template>
  <div class="home">
    <!--Top section-->
    <section>
      <topSection :isSliderOn="true" />
    </section>
    <!--Yapılanlar-->
    <section>
      <works />
    </section>
    <!--Projects-->
    <section>
      <projects />
    </section>
    <!--Contact us-->
    <section>
      <contactUs />
    </section>
    <!--Footer-->
    <footerr>
      <footer />
    </footerr>
  </div>
</template>

<script>
import topSection from '@/components/home/TopSection.vue';
import works from '@/components/home/Works.vue';
import projects from '@/components/home/Projects.vue';
import contactUs from '@/components/home/ContactUs.vue';
import footerr from '@/components/home/Footer.vue';

export default {
  components: {
    topSection,
    works,
    projects,
    contactUs,
    footerr,
  },
};
</script>

<style scoped></style>
