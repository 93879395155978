<template>
  <div class="works">
    <h2 class="text-center headerOne">
      Üstün Teknik Servis
    </h2>
    <div class="container">
      <div class="row">
        <div class="col-md-4 align">
          <img
            class="img-fluid"
            src="../../assets/logo-icon/maskot1.png"
            width="126"
            height="101"
          />
          <ul class="list-group">
            <li class="list-group-item">
              <!-- <router-link
                :to="{ name: 'Works', params: { id: 'elektrik-kornij-sensor-diafon' } }"
                class="link"
                ><img src="../../assets/logo-icon/elektrik.png" class="image" />
                Elektrik Ustası
              </router-link> -->
              <ul>
                <img src="../../assets/logo-icon/elektrik.png" class="image" />
                Elektrik Ustası
              </ul>
            </li>
            <li class="list-group-item">
              <!-- <router-link :to="{ name: 'Works', params: { id: 'insaat' } }" class="link">
                <img src="../../assets/logo-icon/insaat.png" class="image" />
                İnşaat Tadilat
              </router-link> -->
              <ul>
                <img src="../../assets/logo-icon/insaat.png" class="image" />
                İnşaat Tadilat
              </ul>
            </li>
            <li class="list-group-item">
              <!-- <router-link
                :to="{ name: 'Works', params: { id: 'elektrik-kornij-sensor-diafon' } }"
                class="link"
              >
                <img src="../../assets/logo-icon/diafon.png" class="image" />
                Diafon Hizmetleri
              </router-link> -->
              <ul>
                <img src="../../assets/logo-icon/diafon.png" class="image" />
                Diafon Hizmetleri
              </ul>
            </li>
            <li class="list-group-item">
              <!-- <router-link :to="{ name: 'Works', params: { id: 'klima' } }" class="link">
                <img src="../../assets/logo-icon/klima.png" class="image" />
                Klima Bakım Kurulum ve Tamiri
              </router-link> -->
              <ul>
                <img src="../../assets/logo-icon/klima.png" class="image" />
                Klima Bakım Kurulum ve Tamiri
              </ul>
            </li>
          </ul>
        </div>
        <div class="col-md-4 align">
          <img
            class="img-fluid"
            src="../../assets/logo-icon/maskot2.png"
            width="126"
            height="101"
          />
          <ul class="list-group">
            <li class="list-group-item">
              <!-- <router-link :to="{ name: 'Works', params: { id: 'uydu-merkezi' } }"
                class="link">
                <img src="../../assets/logo-icon/uydu.png" class="image" />
                Uydu Kurulumu
              </router-link> -->
              <ul>
                <img src="../../assets/logo-icon/uydu.png" class="image" />
                Uydu Kurulumu
              </ul>
            </li>
            <li class="list-group-item">
              <!-- <router-link :to="{ name: 'Works', params: { id: 'uydu-merkezi' } }"
              class="link">
                <img src="../../assets/logo-icon/uydu.png" class="image" />
                Merkezi Uydu Sistemleri
              </router-link> -->
              <ul>
                <img src="../../assets/logo-icon/uydu.png" class="image" />
                Merkezi Uydu Sistemleri
              </ul>
            </li>
            <li class="list-group-item">
              <!-- <router-link :to="{ name: 'Works', params: { id: 'su-sohben' } }" class="link">
                <img src="../../assets/logo-icon/su.png" class="image" />
                Su Tesisatı Tamiri
              </router-link> -->
              <ul>
                <img src="../../assets/logo-icon/su.png" class="image" />
                Su Tesisatı Tamiri
              </ul>
            </li>
            <li class="list-group-item">
              <!-- <router-link :to="{ name: 'Works', params: { id: 'evAletleri' } }" class="link">
                <img src="../../assets/logo-icon/ev_aletleri.png" class="image" />
                Ev Aletleri Tamiri
              </router-link> -->
              <ul>
                <img src="../../assets/logo-icon/ev_aletleri.png" class="image" />
                Ev Aletleri Tamiri
              </ul>
            </li>
          </ul>
        </div>
        <div class="col-md-4 align">
          <img
            class="img-fluid"
            src="../../assets/logo-icon/maskot3.png"
            width="126"
            height="101"
          />
          <ul class="list-group">
            <li class="list-group-item">
              <!-- <router-link
                :to="{ name: 'Works', params: { id: 'elektrik-kornij-sensor-diafon' } }"
                class="link"
              >
                <img src="../../assets/logo-icon/ev_aletleri.png" class="image" />
                Kornij Montajı
              </router-link> -->
              <ul>
                <img src="../../assets/logo-icon/ev_aletleri.png" class="image" />
                Kornij Montajı
              </ul>
            </li>
            <li class="list-group-item">
              <!-- <router-link :to="{ name: 'Works', params: { id: 'su-sohben' } }" class="link">
                <img src="../../assets/logo-icon/ev_aletleri.png" class="image" />
                Şohben Montajı
              </router-link> -->
              <ul>
                <img src="../../assets/logo-icon/ev_aletleri.png" class="image" />
                Şohben Montajı
              </ul>
            </li>
            <li class="list-group-item">
              <!-- <router-link
                :to="{ name: 'Works', params: { id: 'elektrik-kornij-sensor-diafon' } }"
                class="link"
              >
                <img src="../../assets/logo-icon/sensor.png" class="image" />
                Sensör hizmetleri
              </router-link> -->
              <ul>
                <img src="../../assets/logo-icon/sensor.png" class="image" />
                Sensör hizmetleri
              </ul>
            </li>
            <li class="list-group-item">
              <!-- <router-link :to="{ name: 'Works', params: { id: 'beyazEsya' } }" class="link">
                <img src="../../assets/logo-icon/camasrMakinesi.png" class="image" />
                Beyaz Eşya Kurulum ve Arızıa Hizmetleri
              </router-link> -->
              <ul>
                <img src="../../assets/logo-icon/camasrMakinesi.png" class="image" />
                Beyaz Eşya Kurulum ve Arızıa Hizmetleri
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.works {
  margin-top: 25px;
}
.headerOne {
  font-weight: bold;
}
.list-group-item {
  border: none !important;
}
.link {
  color: black;
}
.link:hover {
  color: red;
}
.image {
  width: 30px;
}
</style>
