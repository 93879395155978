<template>
  <div class="projects">
    <div class="projects-title">
      <h6 class="text-center">KALİTELİ İŞLERİMİZİ KEŞFEDİN</h6>
      <h1 class="text-center">Son işlerimiz</h1>
    </div>
    <!-- Swiper -->
    <carousel-3d>
      <slide :index="0">
        <img src="../../assets/isler/1.jpeg" alt="" />
      </slide>
      <slide :index="1">
        <img src="../../assets/isler/2.jpeg" alt="" />
      </slide>
      <slide :index="2">
        <img src="../../assets/isler/3.jpeg" alt="" />
      </slide>
      <slide :index="3">
        <img src="../../assets/isler/4.jpeg" alt="" />
      </slide>
      <slide :index="4">
        <img src="../../assets/isler/5.jpeg" alt="" />
      </slide>
      <slide :index="5">
        <img src="../../assets/isler/6.jpeg" alt="" />
      </slide>
      <slide :index="6">
        <img src="../../assets/isler/7.jpeg" alt="" />
      </slide>
      <slide :index="7">
        <img src="../../assets/isler/8.jpeg" alt="" />
      </slide>
      <slide :index="8">
        <img src="../../assets/isler/9.jpeg" alt="" />
      </slide>
      <slide :index="9">
        <img src="../../assets/isler/10.jpeg" alt="" />
      </slide>
      <slide :index="10">
        <img src="../../assets/isler/11.jpeg" alt="" />
      </slide>
      <slide :index="11">
        <img src="../../assets/isler/12.jpeg" alt="" />
      </slide>
      <slide :index="12">
        <img src="../../assets/isler/13.jpeg" alt="" />
      </slide>
      <slide :index="13">
        <img src="../../assets/isler/14.jpeg" alt="" />
      </slide>
      <slide :index="14">
        <img src="../../assets/isler/15.jpeg" alt="" />
      </slide>
      <slide :index="15">
        <img src="../../assets/isler/16.jpeg" alt="" />
      </slide>
      <slide :index="16">
        <img src="../../assets/isler/17.jpeg" alt="" />
      </slide>
      <slide :index="17">
        <img src="../../assets/isler/18.jpeg" alt="" />
      </slide>
      <slide :index="18">
        <img src="../../assets/isler/19.jpeg" alt="" />
      </slide>
      <slide :index="19">
        <img src="../../assets/isler/20.jpeg" alt="" />
      </slide>
      <slide :index="20">
        <img src="../../assets/isler/21.jpeg" alt="" />
      </slide>
      <slide :index="21">
        <img src="../../assets/isler/22.jpeg" alt="" />
      </slide>
    </carousel-3d>
    <div class="underline"></div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  components: {
    Carousel3d,
    Slide,
  },
};
</script>

<style>
.projects {
  margin-top: 50px;
}
.projects-title {
  color: white;
  background-color: #ed1c24;
  padding-top: 40px;
  padding-bottom: 40px;
  font-weight: bold;
}
.projects-title h1 {
  font-weight: bolder;
}
.underline {
  height: 15px;
  margin-top: 2px;
  background-color: #ed1c24;
}
</style>
